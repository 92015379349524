import styled from "styled-components"

const ContactContainer = styled.div`
    height: 70%;
    background-color: ${({ theme }) => theme.colors.blue};
`

const WhiteContainer = styled.div`
    ${({theme}) => theme.common.whiteBackground};
`

export const ContactComponent = () =>{
    return (
        <WhiteContainer>
            <ContactContainer>
               
            </ContactContainer>
        </WhiteContainer>
    )
}