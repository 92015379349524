import styled from "styled-components";
import logo from "../image/owner.png";
import { Pagination, Mousewheel, Keyboard } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "../App.styles.css";

export const FourthMobileComponent = () => {
  return (
    <WhiteContainer>
      <FourthContainer>
        <ImgDivStyle>
          <ImageStyle src={logo}></ImageStyle>
        </ImgDivStyle>
        <DivTitleStyle>
          <SpanTitleStyle>가맹점주 사장님</SpanTitleStyle>
          <span>
            께서
            <br /> 사용하는 기능
          </span>
        </DivTitleStyle>
        <Swiper
          pagination={{ clickable: true }} // 우측의 점을 클릭했을 때, 클릭한 슬라이드로 이동하게 됩니다.
          mousewheel // 마우스 휠 동작을 허용합니다.
          keyboard // 키보드 방향키에 의한 동작을 허용합니다.
          modules={[Pagination, Mousewheel, Keyboard]} // 페이지네이션, 마우스휠, 키보드 등을 사용하려면 모듈을 import해줘야 합니다.
          allowTouchMove // 터치 동작을 허용합니다.
          className="main_slider"
          threshold={20} // 터치 감도를 조정합니다. 숫자가 클수록 터치에 반응하지 않습니다.
          speed={500} // 슬라이드가 넘어가는 속도를 조정합니다. 단위는 ms입니다.
        >
          <SwiperSlide>
            <div>
              <DivStyle>
                <div>
                  <div>
                    <SpanContentsTitleStyle>
                      <BodyButton />
                      &nbsp;배달료 카드 충전
                    </SpanContentsTitleStyle>
                  </div>
                  <TextDiv>
                    <SpanContentsStyle>
                      신용카드 및 체크카드로 배달료를 충전하여 부가세, 비용처리 등 간편한 충전이 가능합니다.
                    </SpanContentsStyle>
                  </TextDiv>
                </div>
              </DivStyle>
              <DivStyle>
                <div>
                  <div>
                    <SpanContentsTitleStyle>
                      <BodyButton />
                      &nbsp;모든 POS프로그램 연동
                    </SpanContentsTitleStyle>
                  </div>
                  <TextDiv>
                    <SpanContentsStyle>
                      푸드테크, 포스피드, 매장천사 등 모든 포스프로그램과 연동 가능합니다.
                    </SpanContentsStyle>
                  </TextDiv>
                </div>
              </DivStyle>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div>
              <DivStyle>
                <div>
                  <div>
                    <SpanContentsTitleStyle>
                      <BodyButton />
                      &nbsp;상점 모바일APP 제공
                    </SpanContentsTitleStyle>
                  </div>
                  <TextDiv>
                    <SpanContentsStyle>
                      모바일 버전으로 밖에서도 편리하게 사용할 수 있는 앱 연동 시스템입니다.
                    </SpanContentsStyle>
                  </TextDiv>
                </div>
              </DivStyle>
              <DivStyle>
                <div>
                  <div>
                    <SpanContentsTitleStyle>
                      <BodyButton />
                      &nbsp;주문앱 연동
                    </SpanContentsTitleStyle>
                  </div>
                  <TextDiv>
                    <SpanContentsStyle>
                      배달의민족, 요기요 등 연결이 되어있어 편리한 배송요청이 가능합니다.
                    </SpanContentsStyle>
                  </TextDiv>
                </div>
              </DivStyle>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div>
              <DivStyle>
                <div>
                  <div>
                    <SpanContentsTitleStyle>
                      <BodyButton />
                      &nbsp;완벽한 주소검색
                    </SpanContentsTitleStyle>
                  </div>
                  <TextDiv>
                    <SpanContentsStyle>
                      네이버 주소검색과 동일한 주소 검색 기능을 제공합니다.
                    </SpanContentsStyle>
                  </TextDiv>
                </div>
              </DivStyle>
              <DivStyle>
                <div>
                  <div>
                    <SpanContentsTitleStyle>
                      <BodyButton />
                      &nbsp;매출통계 페이지 제공
                    </SpanContentsTitleStyle>
                  </div>
                  <TextDiv>
                    <SpanContentsStyle>
                      내가 충전한 마일리지가 어떻게, 얼마나 사용되는지 확실한 통계 페이지를 제공합니다.
                    </SpanContentsStyle>
                  </TextDiv>
                </div>
              </DivStyle>
            </div>
          </SwiperSlide>
        </Swiper>
      </FourthContainer>
    </WhiteContainer>
  );
};
const FourthContainer = styled.div`
  height: 70%;
`;

const WhiteContainer = styled.div`
  //${({ theme }) => theme.common.whiteBackground};
  height: 115%;
`;

const ImgDivStyle = styled.div`
  width: 95%;
  text-align: center;
`;

const ImageStyle = styled.img`
  width: 120px;
`;

const DivTitleStyle = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
  font-size: 35px;
  font-weight: bold;
`;
const SpanTitleStyle = styled.span`
  color: #39b1c1;
`;
const DivStyle = styled.div`
  width: 300px;
  // height: 110px;
  position: relative;
  padding: 10px;
  margin-bottom: 30px;
  border-radius: 20px;
  box-shadow: 4px 7px 15px 1px lightgrey;
`;
const SpanContentsTitleStyle = styled.span`
  font-size: 25px;
  font-weight: bold;
  line-height: 50px;
`;

const SpanContentsStyle = styled.span`
  color: #909090;
  font-size: 15px;
  word-break: keep-all;
`;
const BodyButton = styled.div`
  margin-left: 10px;
  border-radius: 8.5px;
  width: 25px;
  height: 10px;
  background-color: #ebebeb;
`;
const TextDiv = styled.div`
  padding: 0 10px;
`