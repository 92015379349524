import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../image/고고라이더스 로고.png";

export const FirstMobileComponent = () => {
  return (
    <FirstContainer>
      <DivStyle>
        <ImgStyle src={logo}></ImgStyle>
      </DivStyle>
      <ButtonDivStyle>
        <ButtonStyle
          onClick={() => window.scrollTo({ top: 35000, behavior: "smooth" })}
        >
          다운로드
        </ButtonStyle>
      </ButtonDivStyle>
    </FirstContainer>
  );
};

const FirstContainer = styled.div`
  width: 100%;
  height: 10%;
  background-color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.common.flexCenterColumn};
`;

const WhiteContainer = styled.div`
  ${({ theme }) => theme.common.whiteBackground};
`;
const DivStyle = styled.div`
  position: absolute;
  left: 30px;
  top: 20px;
`;
const ImgStyle = styled.img`
  width: 45%;
`;
const ButtonStyle = styled.button`
  margin-left: 30px;
  background-color: white;
  border: none;
  font-size: 15px;
  color: black;
`;
const ButtonDivStyle = styled.div`
  position: absolute;
  right: 20px;
  top: 30px;
`;
