import styled from "styled-components";
import logo from "../image/riders.png";
import $ from "jquery";

const FiveContainer = styled.div`
  height: 70%;
`;

const WhiteContainer = styled.div`
  ${({ theme }) => theme.common.whiteBackground};
`;
const Headerspan = styled.div`
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 2.1vw;
  line-height: 3vw;
  display: flex;
  justify-content: center;
`;
const Header = styled.div`
  width: 100%;
  height: 16%;
`;
const Hp = styled.text`
  color: #39b1c1;
`;

const HeaderSecondDiv = styled.div`
  color: #919191;
  font-style: normal;
  font-weight: 350;
  font-size: 1.1vw;
  line-height: 2vw;
  display: flex;
  justify-content: center;
`;
const HeaderMarginDiv = styled.div`
  margin-top: 10px;
`;
const FiveContainerDiv = styled.div`
  height: 100%;
`;

const Body = styled.div`
  position: absolute;
  padding-top: 3%;
  left: 14%;
  width: 85%;
  //height:84%;
  display: flex;
`;

const BodyDiv = styled.div`
  flex: 1;
  margin-left: 4%;
`;
const Body1Div = styled.div`
  flex: 1;
`;
const BodyContent = styled.div`
  padding-bottom: 5%;
  flex: 1;
`;
const BodyContentFirstP = styled.div.attrs({
  className: "si",
})`
  font-style: normal;
  font-weight: 700;
  font-size: 1.8vw;
  line-height: 3vw;
  color: #000000;
`;

const BodyContentSecondP = styled.text`
  color: #919191;
  font-size: 0.9vw;
  font-weight: 350;
  line-height: 1.7vw;
  letter-spacing: -0.05em;
  text-align: left;
`;

const ImgStyle = styled.img`
  width: 15vw;
  margin-left: -20%;
  margin-top: 3%;
`;
const BodyButton = styled.div.attrs({
  className: "sc",
})`
  border-radius: 8.5px;
  width: 1.3vw;
  height: 0.5vw;
  background-color: #ebebeb;
  &:hover ~ ${BodyContentFirstP} {
    color: #ed6d17;
  }
  &:hover {
    background-color: #ed6d17;
  }
`;

export const FiveComponent = () => {
  return (
    <WhiteContainer>
      <FiveContainer>
        <FiveContainerDiv>
          <Header>
            <Headerspan>
              {" "}
              <Hp>배달대행사 사장</Hp>님이 사용하는 기능
            </Headerspan>
            <HeaderMarginDiv></HeaderMarginDiv>
            <HeaderSecondDiv>
              대행사 사장님의 사용편의를 위해 다양한 기능을 제공하고 있습니다.
            </HeaderSecondDiv>
          </Header>
          <Body>
            <BodyDiv>
              <BodyContent>
                <BodyButton></BodyButton>
                <BodyContentFirstP>자유로운 콜 공유</BodyContentFirstP>
                <BodyContentSecondP>
                  지사 간의 콜의 공유가 가능하므로 <br></br>
                  문제없이 콜을 받을 수 있습니다.{" "}
                </BodyContentSecondP>
              </BodyContent>
              <BodyContent>
                <BodyButton></BodyButton>
                <BodyContentFirstP>전연령 시간제보험 가입</BodyContentFirstP>
                <BodyContentSecondP>
                  국내 최초 연령상관없이 오토바이 라이센서를 <br></br>
                  소지하고있다면 시간제보험 가입이 가능합니다.{" "}
                </BodyContentSecondP>
              </BodyContent>
              <BodyContent>
                <BodyButton></BodyButton>
                <BodyContentFirstP>국내최저가 렌탈 및 리스</BodyContentFirstP>
                <BodyContentSecondP>
                  최저가 시간제 보험 가입으로 <br></br>
                  최저가 렌탈 및 리스 이용이 가능합니다.{" "}
                </BodyContentSecondP>
              </BodyContent>
            </BodyDiv>
            <Body1Div>
              <BodyContent>
                <BodyButton></BodyButton>
                <BodyContentFirstP>섬세한 설정</BodyContentFirstP>
                <BodyContentSecondP>
                  특정기사 배차제한, 관리비, 배달료 등<br></br>
                  섬세한 설정이 가능합니다.{" "}
                </BodyContentSecondP>
              </BodyContent>
              <BodyContent>
                <BodyButton></BodyButton>
                <BodyContentFirstP>간편한 세금처리</BodyContentFirstP>
                <BodyContentSecondP>
                  한 번만 설정해도 손쉬운 세금처리를 <br></br>할 수 있습니다.{" "}
                </BodyContentSecondP>
              </BodyContent>
              <BodyContent>
                <BodyButton></BodyButton>
                <BodyContentFirstP>라이더 기사 관리</BodyContentFirstP>
                <BodyContentSecondP>
                  메세지 사용과 실시간 관제 사용으로<br></br>
                  기사 배차 관리가 용이합니다.
                </BodyContentSecondP>
              </BodyContent>
            </Body1Div>
            <BodyDiv>
              <ImgStyle src={logo}></ImgStyle>
            </BodyDiv>
          </Body>
        </FiveContainerDiv>
      </FiveContainer>
    </WhiteContainer>
  );
};
$(function () {
  $(".si")
    .mouseover(function () {
      $(this)
        .css("color", "#ED6D17")
        .prev()
        .css("background", "#ED6D17")
        .css("color", "#ED6D17");
    })
    .mouseout(function () {
      $(this)
        .css("color", "black")
        .prev()
        .css("color", "black")
        .css("background", "#EBEBEB")
        .css("color", "white");
    });

  $(".sc")
    .mouseover(function () {
      $(this).css("background", "#ED6D17").next().css("color", "#ED6D17");
    })
    .mouseout(function () {
      $(this)
        .css("color", "white")
        .css("background", "#EBEBEB")
        .next()
        .css("color", "black");
    });
});
