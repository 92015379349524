import styled from "styled-components";
import logo from "../image/riders_app_master.png";
import $ from "jquery";

const FourthContainer = styled.div`
  height: 70%;
`;

const WhiteContainer = styled.div`
  ${({ theme }) => theme.common.whiteBackground};
`;
const Headerspan = styled.div`
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 2.1vw;
  line-height: 55px;
  display: flex;
  justify-content: center;
`;
const Header = styled.div`
  width: 100%;
  height: 16%;
`;
const Hp = styled.text`
  color: #39b1c1;
`;

const HeaderSecondDiv = styled.div`
  color: #919191;
  font-style: normal;
  font-weight: 350;
  font-size: 1.1vw;
  line-height: 2vw;
  display: flex;
  justify-content: center;
`;
const HeaderMarginDiv = styled.div`
  margin-top: 10px;
`;
const FourthContainerDiv = styled.div`
  height: 100%;
`;

const Body = styled.div`
  position: absolute;
  padding-top: 3%;
  left: 14%;
  width: 75%;
  //height:84%;
  display: flex;
`;

const BodyDiv = styled.div`
  flex: 1;
  margin-left: 4%;
`;
const Body1Div = styled.div`
  flex: 1;
`;
const BodyContent = styled.div`
  padding-bottom: 5%;
  flex: 1;
`;
const BodyContentFirstP = styled.div.attrs({
  className: "si",
})`
  font-style: normal;
  font-weight: 700;
  font-size: 1.8vw;
  line-height: 3vw;
  color: #000000;
`;

const BodyContentSecondP = styled.text`
  color: #919191;
  font-size: 1vw;
  font-weight: 350;
  line-height: 2vw;
  letter-spacing: -0.05em;
  text-align: left;
`;
const ImgStyle = styled.img`
  width: 19vw;
  padding-top: 10%;
`;
const BodyButton = styled.div.attrs({
  className: "sc",
})`
  border-radius: 8.5px;
  width: 1.3vw;
  height: 0.5vw;
  background-color: #ebebeb;
  &:hover ~ ${BodyContentFirstP} {
    color: #ed6d17;
  }
  &:hover {
    background-color: #ed6d17;
  }
`;

export const FourthComponent = () => {
  return (
    <WhiteContainer>
      <FourthContainer>
        <FourthContainerDiv>
          <Header>
            <Headerspan>
              {" "}
              <Hp>가맹점주 사장님</Hp>이 사용하는 기능
            </Headerspan>
            <HeaderMarginDiv></HeaderMarginDiv>
            <HeaderSecondDiv>
              자영업 사장님의 사용편의를 위해 다양한 기능을 제공하고 있습니다.
            </HeaderSecondDiv>
          </Header>
          <Body>
            <BodyDiv>
              <ImgStyle src={logo}></ImgStyle>
            </BodyDiv>
            <BodyDiv>
              <BodyContent>
                <BodyButton></BodyButton>
                <BodyContentFirstP>배달료 카드 충전</BodyContentFirstP>
                <BodyContentSecondP>
                  신용카드 및 체크카드로 배달료를 충전하여<br></br>
                  부가세, 비용처리 등 간편한 충전이 가능합니다.
                </BodyContentSecondP>
              </BodyContent>
              <BodyContent>
                <BodyButton></BodyButton>
                <BodyContentFirstP>상점 모바일APP 제공</BodyContentFirstP>
                <BodyContentSecondP>
                  모바일 버전으로 밖에서도 편리하게<br></br>
                  사용할 수 있는 앱 연동 시스템입니다.{" "}
                </BodyContentSecondP>
              </BodyContent>
              <BodyContent>
                <BodyButton></BodyButton>
                <BodyContentFirstP>완벽한 주소검색</BodyContentFirstP>
                <BodyContentSecondP>
                  네이버 주소검색과 동일한 주소 검색 <br></br>
                  기능을 제공합니다.
                </BodyContentSecondP>
              </BodyContent>
            </BodyDiv>
            <Body1Div>
              <BodyContent>
                <BodyButton></BodyButton>
                <BodyContentFirstP>모든 POS프로그램 연동</BodyContentFirstP>
                <BodyContentSecondP>
                  푸드테크, 포스피드, 매장천사 등 모든<br></br>
                  포스프로그램과 연동 가능합니다.{" "}
                </BodyContentSecondP>
              </BodyContent>
              <BodyContent>
                <BodyButton></BodyButton>
                <BodyContentFirstP>주문앱 연동</BodyContentFirstP>
                <BodyContentSecondP>
                  배달의민족, 요기요 등 연결이 되어있어 <br></br>
                  편리한 배송요청이 가능합니다.
                </BodyContentSecondP>
              </BodyContent>
              <BodyContent>
                <BodyButton></BodyButton>
                <BodyContentFirstP>매출통계 페이지 제공</BodyContentFirstP>
                <BodyContentSecondP>
                  내가 충전한 마일리지가 어떻게, 얼마나<br></br>
                  사용되는지 확실한 통계 페이지를 제공합니다.
                </BodyContentSecondP>
              </BodyContent>
            </Body1Div>
          </Body>
        </FourthContainerDiv>
      </FourthContainer>
    </WhiteContainer>
  );
};
$(function () {
  $(".si")
    .mouseover(function () {
      $(this)
        .css("color", "#ED6D17")
        .prev()
        .css("background", "#ED6D17")
        .css("color", "#ED6D17");
    })
    .mouseout(function () {
      $(this)
        .css("color", "black")
        .prev()
        .css("color", "black")
        .css("background", "#EBEBEB")
        .css("color", "white");
    });

  $(".sc")
    .mouseover(function () {
      $(this).css("background", "#ED6D17").next().css("color", "#ED6D17");
    })
    .mouseout(function () {
      $(this)
        .css("color", "white")
        .css("background", "#EBEBEB")
        .next()
        .css("color", "black");
    });
});
