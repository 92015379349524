import styled from "styled-components";
import riders_app_master from "../image/riders_app_master_cut.png";

export const ThirdMobileComponent = () => {
  return (
    <WhiteContainer>
      <ThirdContainer>
        <DivTitleStyle>
          <span>다양한 혜택과 서비스</span>
        </DivTitleStyle>
        <DivStyle>
          <DivContentsStyle>
            <DivContentsSubStyle>
              <DivTitleLineStyle>
                <SpanNumStyle>01&nbsp;</SpanNumStyle>
                <SpanTitleStyle>신속한 응대</SpanTitleStyle>
                <br />
              </DivTitleLineStyle>
              <DivContentsLineStyle>
                <SpanStyle>· 1년 365일 카드 VAN 등록</SpanStyle>
                <br />
                <SpanBoldStyle>
                  · 평일, 주말, 휴일 모든 시간대 응대가능
                </SpanBoldStyle>
              </DivContentsLineStyle>
            </DivContentsSubStyle>
          </DivContentsStyle>
        </DivStyle>
        <DivStyle>
          <DivContents1Style>
            <DivContentsSubStyle>
              <DivTitleLineStyle>
                <SpanNumStyle>02&nbsp;</SpanNumStyle>
                <SpanTitleStyle>지원 서비스</SpanTitleStyle>
                <br />
              </DivTitleLineStyle>
              <DivContentsLineStyle>
                <SpanBoldStyle>· 배달료 신용, 체크카드 충전 가능</SpanBoldStyle>
                <br />
                <SpanBoldStyle>· 자신만의 프로그램 컨설팅 가능</SpanBoldStyle>
                <br />
                <SpanBoldStyle>· 최고의 UX/UI 변경 가능</SpanBoldStyle>
                <br />
                <SpanStyle>
                  · 기사 수입 및 가맹점 대행료 세금업무
                  <br />
                  <DivSpanSubStyle>&nbsp;&nbsp;100% 처리 가능</DivSpanSubStyle>
                </SpanStyle>
                <br />
                <SpanStyle>· 배달대행 창업 컨설팅 지원</SpanStyle>
                <br />
                <SpanStyle>· 국내 모든 포스사 연동</SpanStyle>
                <br />
              </DivContentsLineStyle>
            </DivContentsSubStyle>
          </DivContents1Style>
        </DivStyle>
        <DivImageStyle>
          <ImgStyle src={riders_app_master}></ImgStyle>
        </DivImageStyle>
        <DivStyle>
          <DivContents2Style>
            <DivContentsSubStyle>
              <DivTitleLineStyle>
                <SpanNumStyle>03&nbsp;</SpanNumStyle>
                <SpanTitleStyle>보험 및 리스</SpanTitleStyle>
                <br />
              </DivTitleLineStyle>
              <DivContentsLineStyle>
                <SpanBoldStyle>
                  · 국내 최저가 전 연령 시간제 보험 적용
                </SpanBoldStyle>
                <br />
                <SpanBoldStyle>· 국내 최저가 렌탈 및 리스 가능</SpanBoldStyle>
                <br />
                <SpanStyle>· 전국 바이크 정비업체 및 순회정비 가능</SpanStyle>
                <br />
                <SpanStyle>· 4대보험 가입 및 세금처리 가능</SpanStyle>
                <br />
              </DivContentsLineStyle>
            </DivContentsSubStyle>
          </DivContents2Style>
        </DivStyle>
      </ThirdContainer>
    </WhiteContainer>
  );
};
const ThirdContainer = styled.div`
  height: 70%;
  background-color: ${({ theme }) => theme.colors.white};
`;

const WhiteContainer = styled.div`
  height: 130%;
`;
const DivStyle = styled.div``;

const DivTitleStyle = styled.div`
  width: 100%;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
`;

const DivContentsStyle = styled.div`
  margin: 40px 20px auto auto;
  // padding-left: 20px;
  // width: 90%;
  // height: 170px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: #f9f9f9;
`;

const DivContents1Style = styled.div`
  margin-top: 40px;
  margin-left: 20px;
  // height: 320px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background-color: #f9f9f9;
`;
const DivContents2Style = styled.div`
  margin: auto;
  margin-top: 55px;
  margin-right: 20px;
  // padding-left: 20px;
  // width: 90%;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: #f9f9f9;
`;

const DivContentsSubStyle = styled.div`
  padding: 20px 0 15px 25px;
  // padding-left: 35px;
  // padding-top: 20px;
`;

const DivTitleLineStyle = styled.div`
  margin-bottom: 20px;
`;

const DivContentsLineStyle = styled.div`
  line-height: 30px;
`;
const DivImageStyle = styled.div`
  width: 100%;
  text-align: right;
  position: relative;
`;
const ImgStyle = styled.img`
  margin-top: -46px;
  margin-left: 45px;
  float: right;
  width: 140px;
  position: absolute;
  right: 0;
`;
const SpanNumStyle = styled.span`
  color: #ed6d17;
  font-size: 25px;
  font-weight: bold;
`;

const SpanTitleStyle = styled.span`
  font-size: 25px;
  font-weight: bold;
`;

const SpanBoldStyle = styled.span`
  font-weight: bold;
  color: #6c6c6c;
  font-size: 17px;
`;

const SpanStyle = styled.span`
  color: #909090;
  font-size: 17px;
`;

const DivSpanSubStyle = styled.div`
  margin-top: -8px;
  margin-bottom: -30px;
`;
