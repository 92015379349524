import styled from "styled-components";
import riders_app_triple from "../image/riders_app_triple.png";

export const SecondComponent = () => {
  return (
    <WhiteContainer>
      <SecondContainer>
        <DivLargeStyle>
          <span>
            언제 어디서나
            <br />
            빠르고 깔끔한 배달대행
          </span>
        </DivLargeStyle>
        <DivSmallStyle>
          <span>
            오토바이 운행 중에도 사용하기 쉽게
            <br />
            운행데이터 효율적인 정보전달이 가능합니다.
          </span>
        </DivSmallStyle>
        <DivButtonStyle
          onClick={() => window.scrollBy({ top: 40000, behavior: "smooth" })}
        >
          <ButtonStyle>다운로드</ButtonStyle>
        </DivButtonStyle>
        <ImgDivStyle>
          <ImgStyle src={riders_app_triple}></ImgStyle>
        </ImgDivStyle>
      </SecondContainer>
    </WhiteContainer>
  );
};
const DivLargeStyle = styled.div`
  position: absolute;
  top: 40%;
  left: 25%;
  font-size: 2vw;
  font-weight: bold;
`;
const DivSmallStyle = styled.div`
  position: absolute;
  top: 52%;
  left: 25%;
  font-size: 1.1vw;
  color: gray;
`;
const DivButtonStyle = styled.div`
  position: absolute;
  top: 60%;
  left: 25%;
`;
const ButtonStyle = styled.button`
  background-color: #ed6d17;
  border-radius: 5vw;
  width: 6.5vw;
  height: 2.5vw;
  border: none;
  font-size: 1.1vw;
  font-weight: bold;
  color: white;
  &:hover {
    cursor: pointer;
  }
`;
const ImgDivStyle = styled.div`
  position: absolute;
  left: 55%;
  top: 25%;
`;
const ImgStyle = styled.img`
  width: 60%;
  height: 50%;
`;
const SecondContainer = styled.div`
  height: 50%;
  background-color: ${({ theme }) => theme.colors.white};
`;

const WhiteContainer = styled.div`
  ${({ theme }) => theme.common.whiteBackground};
`;
