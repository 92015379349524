import { useState } from "react";
import styled from "styled-components";
import logo from "../image/고고라이더스-로고-화이트.png";
import arrow from "../image/화살표.png";

export const FooterComponent = () => {
  return (
    <>
      <FooterStyle>
        <ImageStyle src={logo} />
        <LeftContainer>
          <SpanStyle>(주)고고에프앤디</SpanStyle>
          <br />
          <SpanStyle>
            대표:하성용 | 서울시 마포구 백범로31길 21, 서울창업허브 본관 807호
            <br /> 사업자등록번호:843-86-01322
          </SpanStyle>
          <br />
          <SpanStyle>ⓒ GOGO F&D Corp. All Rights Reserved.</SpanStyle>
        </LeftContainer>
        <RightContainer>
          <div>
            <SpanTitleStyle>패밀리 사이트&nbsp;&nbsp;</SpanTitleStyle>
            <ButtonFadeStyle src={arrow}></ButtonFadeStyle>
            <br />
          </div>
          <ButtonDivStyle
            onClick={() => window.open("http://gogofnd.co.kr", "_blank")}
          >
            <ButtonStyle>고고에프앤디</ButtonStyle>
          </ButtonDivStyle>
          <ButtonDivStyle
            onClick={() =>
              window.open(
                "http://www.cooo.co.kr/files/gogosafe/%EA%B3%A0%EA%B3%A0%EC%84%B8%EC%9D%B4%ED%94%843.apk",
                "_blank"
              )
            }
          >
            <ButtonStyle>고고세이프 설치</ButtonStyle>
            <br />
          </ButtonDivStyle>
        </RightContainer>
      </FooterStyle>
    </>
  );
};
const FooterStyle = styled.footer`
  width: auto;
  height: auto;
  background-color: rgba(33, 33, 33, 0.9);
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 50px;
  font-size: 13px;
  //color: #909090;
  display: grid;
  place-items: center;
  grid-template-columns: auto auto auto auto;
  justify-content: space-around;
  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 5%;
  }
`;

const LeftContainer = styled.div`
  //display: flex;
  color: white;
  flex-direction: column;
  @media screen and (max-width: 500px) {
    margin-top: 5%;
  }
`;
const RightContainer = styled.div`
  //display: flex;
  flex-direction: column;
  margin-left: 48vw;
  @media screen and (max-width: 500px) {
    margin-top: 5%;
  }
`;

const SpanStyle = styled.span``;
const SpanTitleStyle = styled.span`
  color: white;
`;

const ImageStyle = styled.img`
  width: 80px;
  height: 40px;
`;
const ButtonDivStyle = styled.div`
  display: inline-block;
  margin-right: 10px;
  margin-top: 15px;
  width: 120px;
  height: 30px;
  border: 1px solid #909090;
  border-radius: 50px;
`;
const ButtonStyle = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  color: #909090;
  background-color: rgba(255, 255, 255, 0);
  text-align: center;
  &:hover {
    cursor: pointer;
  }
`;

const ButtonFadeStyle = styled.img`
  width: 13px;
  margin-bottom: -2px;
`;
