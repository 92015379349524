import styled from "styled-components";
import logo from "../image/delivery_rider.png";
import { Pagination, Mousewheel, Keyboard } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "../App.styles.css";

export const FiveMobileComponent = () => {
  return (
    <WhiteContainer>
      <FiveContainer>
        <ImgDivStyle>
          <ImageStyle src={logo}></ImageStyle>
        </ImgDivStyle>
        <DivTitleStyle>
          <SpanTitleStyle>배달대행 사장님</SpanTitleStyle>
          <span>
            께서
            <br /> 사용하는 기능
          </span>
        </DivTitleStyle>
        <Swiper
          pagination={{ clickable: true }} // 우측의 점을 클릭했을 때, 클릭한 슬라이드로 이동하게 됩니다.
          mousewheel // 마우스 휠 동작을 허용합니다.
          keyboard // 키보드 방향키에 의한 동작을 허용합니다.
          modules={[Pagination, Mousewheel, Keyboard]} // 페이지네이션, 마우스휠, 키보드 등을 사용하려면 모듈을 import해줘야 합니다.
          allowTouchMove // 터치 동작을 허용합니다.
          className="main_slider"
          threshold={20} // 터치 감도를 조정합니다. 숫자가 클수록 터치에 반응하지 않습니다.
          speed={500} // 슬라이드가 넘어가는 속도를 조정합니다. 단위는 ms입니다.
        >
          <SwiperSlide>
            <div>
              <DivStyle>
                <div>
                  <div>
                    <SpanContentsTitleStyle>
                      <BodyButton />
                      &nbsp;자유로운 콜 공유
                    </SpanContentsTitleStyle>
                  </div>
                  <TextDiv>
                    <SpanContentsStyle>
                      지사 간의 콜의 공유가 가능하므로 문제없이 콜을 받을 수 있습니다.
                    </SpanContentsStyle>
                  </TextDiv>
                </div>
              </DivStyle>
              <DivStyle>
                <div>
                  <div>
                    <SpanContentsTitleStyle>
                      <BodyButton />
                      &nbsp;전연령 시간제보험 가입
                    </SpanContentsTitleStyle>
                  </div>
                  <TextDiv>
                    <SpanContentsStyle>
                      국내 최초 연령상관없이 오토바이 라이센서를 소지하고있다면 시간제보험 가입이 가능합니다.
                    </SpanContentsStyle>
                  </TextDiv>
                </div>
              </DivStyle>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div>
              <DivStyle>
                <div>
                  <div>
                    <SpanContentsTitleStyle>
                      <BodyButton />
                      &nbsp;국내최저가 렌탈 및 리스
                    </SpanContentsTitleStyle>
                  </div>
                  <TextDiv>
                    <SpanContentsStyle>
                      최저가 시간제 보험 가입으로 최저가 렌탈 및 리스 이용이 가능합니다.
                    </SpanContentsStyle>
                  </TextDiv>
                </div>
              </DivStyle>
              <DivStyle>
                <div>
                  <div>
                    <SpanContentsTitleStyle>
                      <BodyButton />
                      &nbsp;섬세한 설정
                    </SpanContentsTitleStyle>
                  </div>
                  <TextDiv>
                    <SpanContentsStyle>
                      특정기사 배차제한, 관리비, 배달료 등 섬세한 설정이 가능합니다.
                    </SpanContentsStyle>
                  </TextDiv>
                </div>
              </DivStyle>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div>
              <DivStyle>
                <div>
                  <div>
                    <SpanContentsTitleStyle>
                      <BodyButton />
                      &nbsp;간편한 세금처리
                    </SpanContentsTitleStyle>
                  </div>
                  <TextDiv>
                    <SpanContentsStyle>
                      한 번만 설정해도 손쉬운 세금처리를 할 수 있습니다.
                    </SpanContentsStyle>
                  </TextDiv>
                </div>
              </DivStyle>
              <DivStyle>
                <div>
                  <div>
                    <SpanContentsTitleStyle>
                      <BodyButton />
                      &nbsp;라이더 기사 관리
                    </SpanContentsTitleStyle>
                  </div>
                  <TextDiv>
                    <SpanContentsStyle>
                      메세지 사용과 실시간 관제 사용으로 기사 배차 관리가 용이합니다.
                    </SpanContentsStyle>
                  </TextDiv>
                </div>
              </DivStyle>
            </div>
          </SwiperSlide>
        </Swiper>
      </FiveContainer>
    </WhiteContainer>
  );
};
const FiveContainer = styled.div`
  height: 70%;
`;

const WhiteContainer = styled.div`
  ${({ theme }) => theme.common.whiteBackground};
`;
const ImgDivStyle = styled.div`
  width: 100%;
  text-align: center;
`;

const ImageStyle = styled.img`
  width: 180px;
`;

const DivTitleStyle = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
  font-size: 35px;
  font-weight: bold;
`;
const SpanTitleStyle = styled.span`
  color: #39b1c1;
`;
const DivStyle = styled.div`
  width: 300px;
  // height: 110px;
  position: relative;
  padding: 10px;
  margin-bottom: 30px;
  border-radius: 20px;
  box-shadow: 4px 7px 15px 1px lightgrey;
`;
const SpanContentsTitleStyle = styled.span`
  font-size: 25px;
  font-weight: bold;
  line-height: 50px;
`;

const SpanContentsStyle = styled.span`
  color: #909090;
  font-size: 15px;
  word-break: keep-all;
`;
const BodyButton = styled.div`
  margin-left: 10px;
  border-radius: 8.5px;
  width: 25px;
  height: 10px;
  background-color: #ebebeb;
`;
const TextDiv = styled.div`
padding: 0 10px;
`