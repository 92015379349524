import styled from "styled-components";
import backImage from "../image/배경1.png";
import pc from "../image/pc.png";
import mobile from "../image/mobile.png";
import back from "../image/run.jpg";
import pcOrange from "../image/pc orange.png";
import mobileOrange from "../image/mobile orange.png";
import logo from "../image/고고라이더스-로고-화이트.png";

import { useState } from "react";

export const SixMobileComponent = () => {
  const [status1, setStatus1] = useState(false);
  const [status2, setStatus2] = useState(false);
  const [status3, setStatus3] = useState(false);
  const [status4, setStatus4] = useState(false);
  const [status5, setStatus5] = useState(false);

  const CilckUrl = (url) => {
    window.open(url, "_blank");
    //setStatus1(!status1);
  };

  return (
    <WhiteContainer>
      <SixContainer>
        <DivTitleStyle>
          <span>프로그램 다운</span>
        </DivTitleStyle>
        <DivSubStyle>
          <SpanSubTitleStyle>지사용</SpanSubTitleStyle>
          <DivButtonStyle
            onClick={() =>
              CilckUrl(
                "http://files.sncbox.com/CompanyPC/install_pc/GGRCompanyPcSetup.msi"
              )
            }
          >
            <ButtonStyle>
              <SpanStyle>
                &nbsp;&nbsp;&nbsp;&nbsp;PC관제 프로그램 다운
              </SpanStyle>
            </ButtonStyle>
          </DivButtonStyle>
          <DivButtonStyle
            onClick={() =>
              CilckUrl(
                "http://files.sncbox.com/CompanyMobile/install_mobile/app-ggrReleaseCompanyUser.apk"
              )
            }
          >
            <ButtonMobileStyle>
              <SpanStyle>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mobile관제 프로그램 다운
              </SpanStyle>
            </ButtonMobileStyle>
          </DivButtonStyle>
        </DivSubStyle>
        <DivSubStyle>
          <SpanSubTitleStyle>상점용</SpanSubTitleStyle>
          <DivButtonStyle
            onClick={() =>
              CilckUrl(
                "http://files.sncbox.com/ShopPC/install_pc/GGRShopPcSetup.msi"
              )
            }
          >
            <ButtonStyle>
              <SpanStyle>&nbsp;&nbsp;&nbsp;&nbsp;PC 프로그램 다운</SpanStyle>
            </ButtonStyle>
          </DivButtonStyle>
          <DivButtonStyle
            onClick={() =>
              CilckUrl(
                "http://files.sncbox.com/CompanyMobile/install_mobile/app-ggrReleaseCompanyUser.apk"
              )
            }
          >
            <ButtonMobileStyle>
              <SpanStyle>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mobile 프로그램 다운
              </SpanStyle>
            </ButtonMobileStyle>
          </DivButtonStyle>
        </DivSubStyle>
        <DivSubStyle>
          <SpanSubTitleStyle>라이더용</SpanSubTitleStyle>
          <DivButtonStyle
            onClick={() =>
              CilckUrl(
                "http://files.sncbox.com/DriverMobile/install_mobile/app-ggrReleaseDriver.apk"
              )
            }
          >
            <ButtonMobileStyle>
              <SpanStyle>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mobile 프로그램 다운
              </SpanStyle>
            </ButtonMobileStyle>
          </DivButtonStyle>
        </DivSubStyle>
        <FooterDivStyle>
          <DivFooterButtonStyle
            onClick={() =>
              window.open("http://gogofnd.co.kr/gogofnd/contact/", "_blank")
            }
          >
            <InquiryButtonStyle>라이더 지원하기 및 문의하기</InquiryButtonStyle>
          </DivFooterButtonStyle>
          <FooterStyle>
            <ImageFooterStyle src={logo} />
            <LeftContainer>
              <span>(주)고고에프앤디</span>
              <br />
              <span>
                대표:하성용 | 서울시 마포구 백범로31길 21, 서울창업허브 본관
                807호
                <br /> 사업자등록번호:843-86-01322
              </span>
              <br />
              <span>ⓒ GOGO F&D Corp. All Rights Reserved.</span>
            </LeftContainer>
          </FooterStyle>
        </FooterDivStyle>
      </SixContainer>
    </WhiteContainer>
  );
};
const SixContainer = styled.div`
  height: 580px;
  width: 100%;
  margin: auto;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(${backImage});
  background-size: auto;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const WhiteContainer = styled.div`
  //${({ theme }) => theme.common.whiteBackground};
  height: 580px;
  width: 100%;
  text-align: center;
`;
const DivTitleStyle = styled.div`
  padding-top: 70px;
  text-align: center;
  color: white;
  font-size: 30px;
  font-weight: bold;
`;
const DivSubStyle = styled.div`
  margin-top: 60px;
  line-height: 40px;
`;

const DivButtonStyle = styled.div`
  width: 100%;
  margin-top: 20px;
`;
const ImgStyle = styled.img`
  margin-top: 5px;
  width: 45px;
`;
const ButtonStyle = styled.button`
  background-image: url(${pc});
  background-size: 50px;
  background-repeat: no-repeat;
  background-position: 30px;
  height: 75px;
  width: 320px;
  background-color: rgba(0, 0, 0, 0.6);
  border: none;
  border-radius: 40px;
  font-size: 18px;
  color: white;
`;
const ButtonMobileStyle = styled.button`
  background-image: url(${mobile});
  background-size: 50px;
  background-repeat: no-repeat;
  background-position: 30px;
  height: 75px;
  width: 320px;
  background-color: rgba(0, 0, 0, 0.6);
  border: none;
  border-radius: 40px;
  font-size: 18px;
  color: white;
`;

const SpanSubTitleStyle = styled.span`
  color: white;
  font-size: 25px;
  font-weight: bold;
`;
const DivFooterButtonStyle = styled.div`
  width: 100%;
  height: 50px;
  background-color: #39b1c1e5;
  color: white;
`;

const InquiryButtonStyle = styled.button`
  width: 100%;
  height: 100%;
  background-color: #39b1c1e5;
  color: white;
  font-size: 15px;
  font-weight: bold;
  border: none;
  text-align: center;
`;
const FooterStyle = styled.footer`
  width: 100%;
  height: 150px;
  background-color: rgba(33, 33, 33, 0.9);
  padding-top: 1%;
  padding-bottom: 1%;
  font-size: 10px;
`;

const LeftContainer = styled.div`
  color: white;
  flex-direction: column;
`;
const ImageFooterStyle = styled.img`
  width: 80px;
  height: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const FooterDivStyle = styled.div`
  margin-top: 125px;
`;

const SpanStyle = styled.span`
  margin-left: 30px;
`;
