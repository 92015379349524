import styled from "styled-components";

import service04 from "../image/service_04.png";
import service05 from "../image/service_05.png";
import service06 from "../image/service_06.png";

const ThirdContainer = styled.div`
  height: 70%;
  background-color: ${({ theme }) => theme.colors.white};
`;

const WhiteContainer = styled.div`
  ${({ theme }) => theme.common.whiteBackground};
`;

const TextColorOrange = styled.text`
  color: #ed6d17;
`;

const TextColorGray = styled.text`
  color: gray;
  font-weight: bold;
  line-height: 1.8vw;
`;

const DivTextParentStyle1 = styled.div`
  position: absolute;
  padding-top: 17%;
  left: 21%;
  width: 70%;
  display: flex;
`;

const DivTextParentStyle2 = styled.div`
  position: absolute;
  padding-top: 20%;
  left: 21%;
  width: 70%;
  display: flex;
`;

const DivTextParentStyle3 = styled.div`
  position: absolute;
  padding-top: 17%;
  left: 25%;
  width: 60%;
  display: flex;
`;

const DivTextChildStyle1 = styled.div`
  flex: 1;
  font-size: 1.6vw;
  font-weight: bold;
  color: black;
`;

const DivTextChildStyle2 = styled.div`
  flex: 1;
  font-size: 1vw;
  color: gray;
`;
const SpanStyle = styled.span`
  line-height: 1.8vw;
`;

const DivImageParentStyle = styled.div`
  position: absolute;
  padding-top: 3%;
  left: 15%;
  width: 70%;
  display: flex;
`;

const DivImageChildStyle = styled.img`
  flex: 1;
  width: 10%;
  margin-left: 10%;
  margin-right: 10%;
`;

//////////////////////////////

// 페이지 타이틀 (content : 다양한 혜택과 서비스)

const Title = styled.div`
  font-size: 2vw;
  text-align: center;
  font-weight: bold;
`;

//////////////////////////////

export const ThirdComponent = () => {
  return (
    <WhiteContainer>
      <ThirdContainer>
        <Title>다양한 혜택과 서비스</Title>
        <DivImageParentStyle>
          <DivImageChildStyle src={service04} />
          <DivImageChildStyle src={service05} />
          <DivImageChildStyle src={service06} />
        </DivImageParentStyle>

        <DivTextParentStyle1>
          <DivTextChildStyle1>
            <span>
              <TextColorOrange>01</TextColorOrange> 신속한 응대
            </span>
          </DivTextChildStyle1>
          <DivTextChildStyle1>
            <span>
              <TextColorOrange>02</TextColorOrange> 지원 서비스
            </span>
          </DivTextChildStyle1>
          <DivTextChildStyle1>
            <span>
              <TextColorOrange>03</TextColorOrange> 보험 및 리스
            </span>
          </DivTextChildStyle1>
        </DivTextParentStyle1>

        <DivTextParentStyle2>
          <DivTextChildStyle2>
            <span>
              · 1년 365일 카드 VAN 등록 <br />
              <TextColorGray>
                · 평일, 주말, 휴일 모든 시간대 응대 가능
              </TextColorGray>
            </span>
          </DivTextChildStyle2>
          <DivTextChildStyle2>
            <span>
              <TextColorGray>· 배달료 신용, 체크카드 충전 가능</TextColorGray>
              <br />
              <TextColorGray>· 자신만의 프로그램 컨설팅</TextColorGray>
              <br />
              <TextColorGray>· 최고의 UX/UI 변경 가능</TextColorGray>
              <br />
              <SpanStyle>· 기사 수입 및 가맹점 대행료 세금 업무</SpanStyle>
              <br />
              &nbsp;&nbsp;100% 처리 가능
              <br />
              <SpanStyle>
                · 배달대행 창업 컨설팅 지원
                <br />
              </SpanStyle>
              <SpanStyle>· 국내 모든 포스사 연동</SpanStyle>
            </span>
          </DivTextChildStyle2>
          <DivTextChildStyle2>
            <span>
              <TextColorGray>
                · 국내 최저가 전 연령 시간제 보험 적용
              </TextColorGray>
              <br />
              <TextColorGray>· 국내 최저가 렌탈 및 리스 가능</TextColorGray>
              <br />
              <SpanStyle>
                · 전국 바이크 정비업체 및 순회정비 가능
                <br />
              </SpanStyle>
              <SpanStyle>
                · 4대보험 가입 및 세금처리 가능
                <br />
              </SpanStyle>
            </span>
          </DivTextChildStyle2>
        </DivTextParentStyle2>
      </ThirdContainer>
    </WhiteContainer>
  );
};
