import styled, { ThemeProvider } from "styled-components";
import theme from "../theme";
import { isMobile } from "react-device-detect";

import { FirstComponent } from "./../components/FirstComponent";
import { SecondComponent } from "./../components/SecondComponent";
import { ThirdComponent } from "../components/ThirdComponent";
import { FourthComponent } from "../components/FourthComponent";
import { FiveComponent } from "./../components/FiveComponent";
import { SixComponent } from "./../components/SixComponent";
import { ContactComponent } from "./../components/ContactComponent";
import { FooterComponent } from "./../components/FooterComponent";
import { InstallComponent } from "./../components/InstallComponent";

import { FirstMobileComponent } from "./../mobile_components/FirstMobileComponent";
import { SecondMobileComponent } from "./../mobile_components/SecondMobileComponent";
import { ThirdMobileComponent } from "../mobile_components/ThirdMobileComponent";
import { FourthMobileComponent } from "../mobile_components/FourthMobileComponent";
import { FiveMobileComponent } from "./../mobile_components/FiveMobileComponent";
import { SixMobileComponent } from "./../mobile_components/SixMobileComponent";

const MainPageContainer = styled.div`
  position: sticky;
  height: 100vh;
  width: calc(100vw - (100vw - 100%));
`;
const MobileMainPageContainer = styled.div`
  position: sticky;
  height: 800px;
  width: calc(100vw - (100vw - 100%));
`;

export const MainPage = () => {
  if (isMobile) {
    return (
      <ThemeProvider theme={theme}>
        <MobileMainPageContainer>
          <FirstMobileComponent />
          <SecondMobileComponent />
          <ThirdMobileComponent />
          <FourthMobileComponent />
          <FiveMobileComponent />
          <SixMobileComponent />
        </MobileMainPageContainer>
      </ThemeProvider>
    );
  }
  return (
    <ThemeProvider theme={theme}>
      <MainPageContainer>
        <FirstComponent />
        <SecondComponent />
        <ThirdComponent />
        <FourthComponent />
        <FiveComponent />
        <SixComponent />
      </MainPageContainer>
    </ThemeProvider>
  );
};
