import styled from "styled-components";
import pc from "../image/pc.png";
import mobile from "../image/mobile.png";
import back from "../image/run.jpg";
import pcOrange from "../image/pc orange.png";
import mobileOrange from "../image/mobile orange.png";
import { useState } from "react";
import { FooterComponent } from "./../components/FooterComponent";

export const SixComponent = () => {
  const [status1, setStatus1] = useState(false);
  const [status2, setStatus2] = useState(false);
  const [status3, setStatus3] = useState(false);
  const [status4, setStatus4] = useState(false);
  const [status5, setStatus5] = useState(false);

  return (
    <WhiteContainer>
      <SixContainer>
        <DivLargeStyle>
          <span>프로그램 다운</span>
        </DivLargeStyle>
        <DivSmallStyle>
          <span>고고라이더스 통합관리 프로그램을 다운받습니다.</span>
        </DivSmallStyle>
        <DivTextParentStyle>
          <DivTextChildStyle>
            <span>지사용</span>
          </DivTextChildStyle>
          <DivTextChildStyle>
            <span>상점용</span>
          </DivTextChildStyle>
          <DivTextChildStyle>
            <span>라이더용</span>
          </DivTextChildStyle>
        </DivTextParentStyle>
        <DivButtonParentStyle>
          <DivButtonChildStyle>
            <ButtonDivStyle
              onClick={() =>
                window.open(
                  "http://files.sncbox.com/CompanyPC/install_pc/GGRCompanyPcSetup.msi",
                  "_blank"
                )
              }
            >
              <ButtonImgStyle src={status1 ? pcOrange : pc}></ButtonImgStyle>
              <ButtonStyle
                onMouseOver={() => setStatus1(true)}
                onMouseOut={() => setStatus1(false)}
              >
                PC관제 프로그램 다운
              </ButtonStyle>
            </ButtonDivStyle>
            <ButtonDivStyle
              onClick={() =>
                window.open(
                  "http://files.sncbox.com/CompanyMobile/install_mobile/app-ggrReleaseCompanyUser.apk",
                  "_blank"
                )
              }
            >
              <ButtonImgStyle
                src={status2 ? mobileOrange : mobile}
              ></ButtonImgStyle>
              <ButtonStyle
                onMouseOver={() => setStatus2(true)}
                onMouseOut={() => setStatus2(false)}
              >
                Mobile관제 프로그램 다운
              </ButtonStyle>
            </ButtonDivStyle>
          </DivButtonChildStyle>
          <DivButtonChildStyle>
            <ButtonDivStyle
              onClick={() =>
                window.open(
                  "http://files.sncbox.com/ShopPC/install_pc/GGRShopPcSetup.msi",
                  "_blank"
                )
              }
            >
              <ButtonImgStyle src={status3 ? pcOrange : pc}></ButtonImgStyle>
              <ButtonStyle
                onMouseOver={() => setStatus3(true)}
                onMouseOut={() => setStatus3(false)}
              >
                PC 프로그램 다운
              </ButtonStyle>
            </ButtonDivStyle>
            <ButtonDivStyle
              onClick={() =>
                window.open(
                  "http://files.sncbox.com/CompanyMobile/install_mobile/app-ggrReleaseCompanyUser.apk",
                  "_blank"
                )
              }
            >
              <ButtonImgStyle
                src={status4 ? mobileOrange : mobile}
              ></ButtonImgStyle>
              <ButtonStyle
                onMouseOver={() => setStatus4(true)}
                onMouseOut={() => setStatus4(false)}
              >
                Mobile 프로그램 다운
              </ButtonStyle>
            </ButtonDivStyle>
          </DivButtonChildStyle>
          <DivButtonChildStyle>
            <ButtonDivStyle
              onClick={() =>
                window.open(
                  " http://files.sncbox.com/DriverMobile/install_mobile/app-ggrReleaseDriver.apk",
                  "_blank"
                )
              }
            >
              <ButtonImgStyle
                src={status5 ? mobileOrange : mobile}
              ></ButtonImgStyle>
              <ButtonStyle
                onMouseOver={() => setStatus5(true)}
                onMouseOut={() => setStatus5(false)}
              >
                Mobile 프로그램 다운
              </ButtonStyle>
            </ButtonDivStyle>
          </DivButtonChildStyle>
        </DivButtonParentStyle>
      </SixContainer>
      <DivButtonStyle
        onClick={() =>
          window.open("http://gogofnd.co.kr/gogofnd/contact/", "_blank")
        }
      >
        <InquiryButtonStyle>라이더 지원하기 및 문의하기</InquiryButtonStyle>
      </DivButtonStyle>
      <FooterComponent />
    </WhiteContainer>
  );
};
const SixContainer = styled.div`
  height: 100%;
  width: 100%;
  /* background-image: linear-gradient(
        rgba(0, 0, 0, 0.6),
        rgba(0, 0, 0, 0.6)
      ), url(${back}); */
`;

const WhiteContainer = styled.div`
  //${({ theme }) => theme.common.whiteBackground};
  width: 100%;
  height: 85%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(${back});
  background-size: cover;
`;
const DivLargeStyle = styled.div`
  padding-top: 7%;
  text-align: center;
  font-size: 2vw;
  font-weight: bold;
  color: white;
`;
const DivSmallStyle = styled.div`
  padding-top: 1%;
  font-size: 1.2vw;
  text-align: center;
  color: #909090;
`;
const DivTextParentStyle = styled.div`
  position: absolute;
  padding-top: 6%;
  left: 12%;
  width: 85%;
  display: flex;
`;
const DivTextChildStyle = styled.div`
  padding-left: 9.5%;
  flex: 1;
  font-size: 1.5vw;
  font-weight: bold;
  color: white;
`;
const DivButtonParentStyle = styled.div`
  position: absolute;
  padding-top: 9%;
  left: 12%;
  width: 85%;
  display: flex;
`;
const DivButtonChildStyle = styled.div`
  flex: 1;
  font-size: 1.3vw;
  font-weight: bold;
  color: white;
`;
const ButtonDivStyle = styled.div`
  padding-top: 5%;
`;
const ButtonStyle = styled.button`
  padding: 3% 10% 3% 16%;
  width: 73%;
  height: 4.5vw;
  font-size: 1.1vw;
  color: white;
  border-radius: 1000px;
  border: 1px solid white;
  background-color: rgba(255, 255, 255, 0);
  &:hover {
    cursor: pointer;
    border: 1px solid #ed6d17;
    color: #ed6d17;
  }
`;

const ButtonImgStyle = styled.img`
  position: absolute;
  margin-top: 0.9vw;
  margin-left: 2vw;
  width: 2.7vw;
`;

const DivButtonStyle = styled.div`
  width: 100%;
  height: 7%;
  background-color: #39b1c1e5;
  color: white;
  font-size: 1vw;
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
`;

const InquiryButtonStyle = styled.button`
  width: 100%;
  height: 100%;
  background-color: #39b1c1e5;
  color: white;
  font-size: 1vw;
  font-weight: bold;
  border: none;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
`;
