// 반응형 디자인위한 함수
const pixelToRem = (size) => `${size / 16}rem`;

// font size 모음
const fontSizes = {
  title: pixelToRem(60),
  subtitle: pixelToRem(30),
  paragraph: pixelToRem(18),
};

// 색상 모음
const colors = {
  black: "#000000",
  grey: "#999999",
  green: "#3cb46e",
  blue: "#000080",
};

// 자주 사용하는 스타일
const common = {
  flexCenter: `
    display: flex;
    justify-contents: center;
    align-items: center;
  `,
  flexCenterColumn: `
    display: flex;
    flex-direction: column;
    justify-contents: center;
    align-items: center;
  `,
  whiteBackground: `
    height: 100%;
    background-color: white;
  `
};

// 이미지크기 모음
const imgSize = {
  sideImg:`

  `,
  InstallBackGroundImg :`

  `,
  InstallSubImg:`

  `,
  contactImg:`

  `,
  subImg:`

  `,
  fuctionImg:`

  `

}

// theme 객체에 감싸서 반환한다.
const theme = {
  fontSizes,
  colors,
  common,
  imgSize
};


export default theme;