import styled from "styled-components"
import logo from "../image/고고라이더스 로고.png"

export const FirstComponent = () =>{
    return (
            <FirstContainer>
                <DivStyle onClick={() => window.location.reload()}>
                        <ImgStyle src={logo}></ImgStyle>
                </DivStyle>
                <ButtonDivStyle>
                        <ButtonStyle onClick={() => window.open('https://smartstore.naver.com/sharefc', '_blank')}>고고쇼핑</ButtonStyle>
                        <ButtonStyle onClick={() => window.open('http://gogofnd.co.kr/gogofnd/contact/', '_blank')}>문의하기</ButtonStyle>
                        <ButtonStyle onClick={() => window.scrollTo({top: 35000, behavior: 'smooth'})}>다운로드</ButtonStyle>
                </ButtonDivStyle>
            </FirstContainer>  
    )
}


const FirstContainer = styled.header`
    position: fixed;
    width: 100%;
    height: 10%;
    background-color: white;
    ${({ theme }) => theme.common.flexCenterColumn};
    border-bottom: 1px solid lightgrey;
    font-family: "맑은 고딕";
    z-index: 1;
`
const DivStyle = styled.div`
    position: absolute;
    left: 15%;
    top: 25%;
    &:hover{
      cursor: pointer;
   }
`
const ImgStyle = styled.img`
    width: 120px;
`
const ButtonStyle = styled.button`
    margin-left: 3vw;
    background-color: white;
    border: none;
    font-size: 1vw;
    &:hover{
      cursor: pointer;
   }
`
const ButtonDivStyle = styled.div`
    position: absolute;
    right: 15%;
    top: 40%;
`