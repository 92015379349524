import styled from "styled-components";

const InstallContainer = styled.div`
  height: 70%;
  background-color: ${({ theme }) => theme.colors.blue};
`;

const WhiteContainer = styled.div`
  ${({ theme }) => theme.common.whiteBackground};
`;

export const InstallComponent = () => {
  return (
    <WhiteContainer>
      <InstallContainer></InstallContainer>
    </WhiteContainer>
  );
};
