import styled from "styled-components";
import riders_app_triple from "../image/riders_app_triple.png";

export const SecondMobileComponent = () => {
  return (
    <WhiteContainer>
      <SecondContainer>
        <DivTitleStyle>
          <span>
            언제 어디서나
            <br /> 빠르고 깔끔한 배달대행
          </span>
        </DivTitleStyle>
        <DivContentsStyle>
          <span>
            오토바이 운행 중에도 사용하기 쉽게
            <br />
            운행데이터 효율적인 정보전달이 가능합니다.
          </span>
        </DivContentsStyle>
        <ImgDivStyle>
          <ImgStyle src={riders_app_triple}></ImgStyle>
        </ImgDivStyle>
        <ButtonDivStyle
          onClick={() => window.scrollTo({ top: 35000, behavior: "smooth" })}
        >
          <ButtonStyle>다운로드</ButtonStyle>
        </ButtonDivStyle>
      </SecondContainer>
    </WhiteContainer>
  );
};

const SecondContainer = styled.div`
  height: 80%;
  background-color: ${({ theme }) => theme.colors.white};
`;

const WhiteContainer = styled.div`
  //${({ theme }) => theme.common.whiteBackground};
`;
const DivTitleStyle = styled.div`
  width: 100%;
  margin-top: 40px;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
`;
const DivContentsStyle = styled.div`
  width: 100%;
  margin-top: 10px;
  text-align: center;
  font-size: 15px;
  color: #909090;
`;
const ImgDivStyle = styled.div`
  width: 100%;
  margin-top: 40px;
  text-align: center;
`;

const ImgStyle = styled.img`
  width: 320px;
`;

const ButtonDivStyle = styled.div`
  margin-top: 30px;
  width: 100%;
  height: 100%;
  border: none;
  text-align: center;
`;

const ButtonStyle = styled.button`
  width: 300px;
  height: 60px;
  font-size: 22px;
  font-weight: bold;
  color: white;
  background-color: #ed6d17;
  border: none;
  border-radius: 40px;
`;
